<template>
  <div>
    <AppBar
      :pageTitle="$route.name === 'Onboarding.Overview' ? 'RegistrationProcessOverview' : `${mainStage}.${subStage}`"
      @previous="previous"
    />
    <SideMenu
      v-if="$route.name !== 'Onboarding.Overview'"
      :steps="steps"
      :icons="icons"
      :mainStage="mainStage"
      :subStage="subStage"
      :editMode="editMode"
    />
    <v-main>
      <v-container class="container">
        <div class="mb-10">
          <v-scroll-x-transition :hide-on-leave="true">
            <router-view ref="view" />
          </v-scroll-x-transition>
        </div>
        <CardNav
          v-if="$route.name !== 'Onboarding.Overview' && !removeCardNav"
          :nextText="nextBtnText"
          :prevText="$t('onboarding.navButtons.back')"
          @next="next"
          @previous="previous"
          :nextLoading="isLoading"
          :nextDisabled="!isFormValid"
          class="navigation-buttons backgroundDark pb-10"
          onboarding
          :prevBtn="!editMode && !$vuetify.breakpoint.xs"
        />
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'OnboardingContainer',
  components: {
    AppBar: lazyLoad('layouts/Onboarding/AppBar'),
    SideMenu: lazyLoad('layouts/Onboarding/SideMenu'),
    CardNav: lazyLoad('components/Shared/CardNav'),
  },
  computed: {
    ...mapGetters('onboarding', [
      'steps',
      'isFormValid',
      'isLoading',
      'icons',
      'editMode',
    ]),
    ...mapGetters('user', [
      'mainStage',
      'subStage',
    ]),
    ...mapGetters('application', [
      'configurations',
    ]),
    nextBtnText() {
      if (this.mainStage === 'videoIdent' && this.subStage === 'Selection') {
        return this.$t('onboarding.navButtons.nextLater');
      }
      if (this.mainStage === 'classification' && this.subStage === 'Overview') {
        return this.$t('onboarding.navButtons.becomePro');
      }
      return this.$t('onboarding.navButtons.next');
    },
    removeCardNav() {
      return (this.configurations?.stage === 'uat' || this.configurations?.stage === 'prod')
        && this.mainStage === 'investorApproval';
    },
  },
  mounted() {
    // if back button is pressed
    window.onpopstate = () => {
      if (this.$router.currentRoute.path.includes('onboarding')) {
        this.$router.push({ name: 'Onboarding.Overview' });
      }
    };
  },
  methods: {
    ...mapActions('user', [
      'previousStep',
      'setSubStage',
    ]),
    previous() {
      const index = this.steps[this.mainStage]
        .findIndex((item) => (item === this.subStage));
      if (index === 0) {
        this.$router.push({
          name: 'Onboarding.Overview',
        });
      } else {
        if (this.subStage === 'Products' || this.subStage === 'Services') {
          this.$refs.view.previous(this.steps[this.mainStage][index]);
          return;
        }
        this.setSubStage(this.steps[this.mainStage][index]);
        this.previousStep();
      }
    },
    next() {
      this.$refs.view.next();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 80vh;
  position: relative;
}
.navigation-buttons {
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100%;
  padding: 12px;
  z-index: 3;
}
</style>
